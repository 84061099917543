<template>
    <b-modal id="change-password-modal" :title="$t('password.change_password')">
        <b-overlay :show="isLoading">
            <validation-observer ref="validation">
                <b-form-group :label="$t('password.old_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('password.old_password')" rules="required">
                            <b-form-input autofocus name="old_password" type="password" v-model="password_change.old_password" />
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
                <b-form-group :label="$t('password.new_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('password.new_password')" rules="required" vid="new_password">
                            <b-form-input name="new_password" type="password" v-model="password_change.new_password" />
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
                <b-form-group :label="$t('password.repeat_new_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('password.repeat_new_password')" rules="required|confirmed:new_password">
                            <b-form-input name="repeat_new_password" type="password" v-model="password_change.repeat_new_password" data-vv-as="new_password"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
            </validation-observer>
        </b-overlay>
        <template #modal-footer="{ cancel }">
            <b-button variant="danger" @click="cancel()">
                <span class="align-middle">
                    {{ $t('general.cancel') }}
                </span>
            </b-button>
            <b-button variant="primary" @click="changePassword">
                {{ $t('password.change_password') }}
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BModal, BFormInput, BButton, BFormGroup, BOverlay
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BModal,
            BFormGroup,
            BFormInput,
            BButton,
            BOverlay,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                password_change: {
                    old_password: '',
                    new_password: '',
                    repeat_new_password: ''
                },
                required,
                isLoading: false
            }
        },
        methods: {
            showModal() {
                this.password_change = {
                    old_password: '',
                    new_password: '',
                    repeat_new_password: ''
                }
                this.$bvModal.show('change-password-modal')
            },
            async changePassword() {
                try {

                    this.isLoading = true

                    const valid = await this.$refs.validation.validate()
                    if (!valid) return
                
                    await this.$http.put('/api/client/v1/users/change_password', this.password_change)
                    this.$printSuccess(this.$t('print.success.password_changed'))
                    this.$bvModal.hide('change-password-modal')      
        
                } catch (err) {
                    if (err && err.response && err.response.data.error === 'incorrect old password') {
                        this.$printWarning(this.$t('print.warning.incorrect_old_password_error'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.password_change'))
                    }
                } finally {
                    this.isLoading = false
                } 
            }
        }
    }
</script>

<style scoped>
  .w-full{
    width: 100%;
  }
</style>

