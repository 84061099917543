<template>
    <div
        class="customizer d-none d-md-block"
        :class="{'open': isCustomizerOpen}"
    >
        <!-- Toggler -->

        <b-link v-if="$store.getters['setup/setupProgress'] === setupProgress.REQUIRED"
                style=" background-color: #ff9f43"
                class="customizer-toggle d-flex align-items-center justify-content-center"
                @click="open"
        >
            <fa :icon="['fas', 'question']" style="font-size: 12px" />
        </b-link>
        <!-- /Toggler -->

        <!-- Header -->
        <div class="customizer-section d-flex justify-content-between align-items-center">
            <div>
                <h4 class="text-uppercase mb-0">
                    {{$t('installation_wizard.installation_wizard')}}
                </h4>
                <small>{{$t('installation_wizard.configure')}}</small>
            </div>
            <feather-icon
                icon="XIcon"
                size="18"
                class="cursor-pointer"
                @click="isCustomizerOpen = !isCustomizerOpen"
            />
        </div>
        <!-- Header -->

        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="ps-customizer-area scroll-area mt-4 ml-2"
        >
            <b-overlay :show="showLoader">
                <app-timeline v-for="(route) in routes" :key="route.title">
                    <app-timeline-item v-if="route.data > 0 && route.hasPermissions"
                                       @click="changeName(route)"
                                       :title="$t(`menu.subcategory.${route.title}`)"
                                       variant="success"
                                       style="cursor: pointer">
                    </app-timeline-item>
                    <app-timeline-item v-else-if="route.data === 0 && route.accessible && route.hasPermissions"
                                       @click="changeName(route)"
                                       :title="$t(`menu.subcategory.${route.title}`)"
                                       variant="optional"
                                       style="cursor: pointer">
                    </app-timeline-item>
                    <app-timeline-item v-else
                                       :tooltip="getTooltip(route.title)"
                                       @click="changeName(route)"
                                       :title="$t(`menu.subcategory.${route.title}`)"
                                       style="cursor: not-allowed;"
                                       variant="secondary">

                    </app-timeline-item>
                </app-timeline>
            </b-overlay>
            <div style=" position: fixed; bottom: 3vh">
                <b-overlay >                                <div class="ml-0 w-100 t-1">
                    <div class="mb-2 ml-0 mr-0 w-100 data-border">
                        <div class="border-text bgCard">
                            {{$t('installation_wizard.usage')}}
                        </div>
                        <div class="pt-1">
                            <b-row >
                                <b-col>
                                    <app-timeline>
                                        <app-timeline-item
                                            :title="$t(`installation_wizard.filled`)"
                                            variant="success">
                                        </app-timeline-item>
                                    </app-timeline>
                                </b-col>
                                <b-col>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <app-timeline>
                                        <app-timeline-item
                                            :title="$t(`installation_wizard.optional`)"
                                            variant="optional">
                                        </app-timeline-item>
                                    </app-timeline>
                                </b-col>
                                <b-col >
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div></b-overlay>
            </div>
        </vue-perfect-scrollbar>
    </div>
</template>

<script>
    import appTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import appTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import {
        BCol,
        BLink, BOverlay, BRow
    } from 'bootstrap-vue'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import useAppCustomizer from './useAppCustomizer'
    import {setupProgress, WizardRequirements} from '@/libs/enums/InstallationWizard'
    import {Tiers} from '@/libs/enums/Tiers'
    import * as Sentry from '@sentry/vue'

    export default {
        computed: {
            access() {
                const client = this.$store.getters['client/clientData']

                const access = []

                if (client.tier === Tiers.Essential) {
                    access.push(
                        {
                            name: WizardRequirements.businessUnits,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: []
                        }
                    )
                } else {
                    access.push(
                        {
                            name: WizardRequirements.businessUnits,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: [WizardRequirements.warehouses]
                        }
                    )
                }


                access.push(
                    {
                        name: WizardRequirements.vat,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.categories,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.vat]
                    },
                    {
                        name: WizardRequirements.subcategories,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.categories]
                    },
                    {
                        name: WizardRequirements.products,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.categories, WizardRequirements.subcategories]
                    },
                    {
                        name: WizardRequirements.orderOptions,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.products]
                    },
                    {
                        name: WizardRequirements.productDisplayGroups,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                        requirements: [WizardRequirements.products]
                    },
                    {
                        name: WizardRequirements.discounts,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.paymentTypes,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.buyers,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.logos,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.printers,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.furs,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.permissions,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: []
                    },
                    {
                        name: WizardRequirements.employees,
                        required: true,
                        hasPermissions: this.$hasPermission(this.$permissions.GeneralRead),
                        requirements: [WizardRequirements.permissions]
                    },
                    {
                        name: WizardRequirements.invoiceConfig,
                        required: false,
                        hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                        requirements: []
                    }
                )


                if (client.tier === Tiers.Standard || client.tier === Tiers.Premium) {
                    access.push(
                        {
                            name: WizardRequirements.warehouses,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: []
                        },
                        {
                            name: WizardRequirements.ingredientCategories,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.warehouses, WizardRequirements.vat]
                        },
                        {
                            name: WizardRequirements.ingredientSubCategories,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.ingredientCategories]
                        },
                        {
                            name: WizardRequirements.ingredients,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.ProductsRead),
                            requirements: [WizardRequirements.ingredientSubCategories]
                        },
                        {
                            name: WizardRequirements.suppliers,
                            required: false,
                            hasPermissions: this.$hasPermission(this.$permissions.InventoryManagement),
                            requirements: []
                        },
                        {
                            name: WizardRequirements.workPremises,
                            required: true,
                            hasPermissions: this.$hasPermission(this.$permissions.ConfigurationRead),
                            requirements: [WizardRequirements.businessUnits]
                        }
                    )
                }

                return access
            },
            setupProgress() {
                return setupProgress
            }
        },
        data() {
            return {
                setupWizard: this.$store.getters['setup/setupWizard'],
                click: '',
                routes: [],
                showLoader : false
            }
        },
        components: {
            BCol,
            BRow,
            BOverlay,
            appTimelineItem,
            appTimeline,
            // BSV
            BLink,

            // 3rd party
            VuePerfectScrollbar

        },
        methods: {
            getTooltip(name) {
                const fill = this.access.find(el => el.name === name)

                if (!fill) {
                    return ''
                }

                if (!fill.hasPermissions) {
                    return 'Nimate ustreznih pravic'
                }

                return ''
            },
            changeName(item) {

                if (!item.hasPermissions) {
                    return
                }

                this.$store.dispatch('setup/setClickedItem', item.title)
                this.$store.dispatch('setup/setRedirect', false)
                this.$router.push('/installation-wizard')
            },
            open() {
                this.isCustomizerOpen = !this.isCustomizerOpen
                if (this.isCustomizerOpen) {
                    this.formatSetupArray()
                }
            },
            async formatSetupArray() {
                this.showLoader = true
                await this.$store.dispatch('setup/getSetupProgress')

                try {
                    const res = await this.$http.get('/api/client/v1/installation_wizard/')
                    this.setupWizard = res.data ?? {}
                } catch (err) {
                    this.$printError(this.$t('print.error.on_load'))
                    Sentry.captureException(err)
                }

                this.routes = []
                if (this.$store.getters['setup/setupProgress'] === setupProgress.NONE) {
                    await this.$router.push('/installation_wizard')
                }

                for (const prop in this.setupWizard) {
                    if (Object.prototype.hasOwnProperty.call(this.setupWizard, prop)) {
                        if (prop === 'id' || prop === 'client_id') {
                            continue
                        }

                        const hasAccess = this.access.findIndex(ele => ele.name === prop)

                        if (hasAccess === -1) {
                            continue
                        }

                        const payload = {
                            title: prop,
                            data: this.setupWizard[`${prop}`],
                            hasPermissions: this.access[hasAccess].hasPermissions

                        }
                        this.routes.push(payload)
                    }
                }
                for (let i = 0; i < this.routes.length; i++) {
                    this.routes[0].accessible = true
                    if (i > 0) {
                        if (this.routes[i].data > 0 || this.routes[i - 1].data > 0) {
                            this.routes[i].accessible = true
                        }
                        for (let j = 0; j < this.access.length; j++) {
                            if (this.access[j].name === this.routes[i].title && !this.access[j].required) {
                                this.routes[i].accessible = true
                            }
                        }
                    }
                }
                this.showLoader = false
            }
        },

        setup() {
            const {
                // Vertical Menu
                isVerticalMenuCollapsed,

                // Customizer
                isCustomizerOpen,

                // Skin
                skin,
                skinOptions,

                // Content Width
                contentWidth,
                contentWidthOptions,

                // RTL
                isRTL,

                // routerTransition
                routerTransition,
                routerTransitionOptions,

                // Layout Type
                layoutType,
                layoutTypeOptions,

                // NavMenu Hidden
                isNavMenuHidden,

                // Navbar
                navbarColors,
                navbarTypes,
                navbarBackgroundColor,
                navbarType,

                // Footer
                footerTypes,
                footerType
            } = useAppCustomizer()

            if (layoutType.value === 'horizontal') {
                // Remove semi-dark skin option in horizontal layout
                const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
                delete skinOptions[skinSemiDarkIndex]

                // Remove menu hidden radio in horizontal layout => As we already have switch for it
                const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
                delete navbarTypes[menuHiddneIndex]
            }

            // Perfect Scrollbar
            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false
            }

            return {
                // Vertical Menu
                isVerticalMenuCollapsed,

                // Customizer
                isCustomizerOpen,

                // Skin
                skin,
                skinOptions,

                // Content Width
                contentWidth,
                contentWidthOptions,

                // RTL
                isRTL,

                // routerTransition
                routerTransition,
                routerTransitionOptions,

                // Layout Type
                layoutType,
                layoutTypeOptions,

                // NavMenu Hidden
                isNavMenuHidden,

                // Navbar
                navbarColors,
                navbarTypes,
                navbarBackgroundColor,
                navbarType,

                // Footer
                footerTypes,
                footerType,

                // Perfect Scrollbar
                perfectScrollbarSettings
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
.data-border{
  border: 2px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  //background: white;
  padding: 0 15px;
  font-weight: bold;

}
</style>
