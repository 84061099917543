<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow"
        :class="[
            { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
            skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
        ]"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">

                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto my-auto">
                        <b-link
                            class="navbar-brand"
                            to="/"
                        >
                            <span v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)" class="brand-logo">
                                <div class="d-flex justify-content-start">
                                    <b-img
                                        :src="appLogoImage"
                                        alt="logo"
                                        class=""
                                        style="height: 40px;"
                                    />
                                    <div>
                                        <div style="margin-top: 5px; height: 20px; font-size: 20px; font-weight: bold;">
                                            IMPRION
                                        </div>
                                        <div>
                                            <span v-if="clientData && clientData.tier === Tiers.Essential" style="font-size: 12px; color: #9BD3AE">
                                                Essential
                                            </span>
                                            <span v-else-if="clientData && clientData.tier === Tiers.Standard" style="font-size: 12px; color: #43ADA5">
                                                Standard
                                            </span>
                                            <span v-else-if="clientData && clientData.tier === Tiers.Premium" style="font-size: 12px; color: #F27179">
                                                Premium
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span v-else class="brand-logo">
                                <div>
                                    <b-img
                                        :src="appLogoImage"
                                        alt="logo"
                                        style="height: 50px;"
                                    />
                                    <div>
                                        <span v-if="clientData && clientData.tier === Tiers.Essential" style="font-size: 12px; color: #9BD3AE">
                                            Essential
                                        </span>
                                        <span v-else-if="clientData && clientData.tier === Tiers.Standard" style="font-size: 12px; color: #43ADA5">
                                            Standard
                                        </span>
                                        <span v-else-if="clientData && clientData.tier === Tiers.Premium" style="font-size: 12px; color: #F27179">
                                            Premium
                                        </span>
                                    </div>
                                </div>

                            </span>
                        </b-link>
                    </li>
                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                icon="XIcon"
                                size="20"
                                class="d-block d-xl-none"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                size="20"
                                class="d-none d-xl-block collapse-toggle-icon"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
                <div class="w-100">
                    <div v-if="userData && (!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered))" class="mr-2" style="width: 100%; position: relative">
                        <v-select v-model="selected_client" class="clientFilter" :searchable="true" :filter-by="findClient"  label="name" :options="allowedClients" :clearable="false" :reduce="ele => ele.id" @option:selected="clientChanged">
                            <template #selected-option="{name, disabled}" style="max-width: 100% !important;" >
                                <div>
                                    <div :class="{'text-danger': disabled}">
                                        {{ getOptionName(name) }}
                                    </div>
                                </div>
                            </template>
                            <template v-slot:option="option">
                                <div style="position:relative;">
                                    <div style="color:#595959!important; ">{{ getOptionName(option.name) }}</div>
                                    <div class="font-weight-bolder" style="color:#595959!important; ">{{getOptionName(option.alias, 20)}}</div>
                                    <div v-if="unreadClients && unreadClients.includes(option.id)" class="unreadDot" />
                                    <div class="d-flex justify-content-end pb-1" v-if="option.disabled">
                                        <b-badge pill variant="danger">{{ $t('installation_wizard.user_disabled') }}</b-badge>
                                    </div>
                                </div>
                            </template>
                        </v-select>
                        <span v-if="unreadMessageCountForAllClients>0" class="notification-dot">
                            {{unreadMessageCountForAllClients}}
                        </span>
                    </div>
                </div>
            </slot>
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />
        <!-- / Shadow -->

        <!-- main menu content-->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >
            <vertical-nav-menu-items
                :items="navMenuItems"
                class="navigation navigation-main mb-5 pb-2"
            />
        </vue-perfect-scrollbar>
        <!-- / main menu content-->
    </div>
</template>

<script>
    import navMenuItems from '@/navigation/vertical'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import { BLink, BImg, BBadge } from 'bootstrap-vue'
    import { provide, computed, ref } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'
    import { $themeConfig } from '@themeConfig'
    import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
    import useVerticalNavMenu from './useVerticalNavMenu'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import Vue from 'vue'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        components: {
            VuePerfectScrollbar,
            VerticalNavMenuItems,
            BLink,
            BImg,
            BBadge,
            vSelect
        },
        props: {
            isVerticalMenuActive: {
                type: Boolean,
                required: true
            },
            toggleVerticalMenuActive: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                allowedClients: [],
                selected_client: null
            }
        },
        computed: {
            Tiers() {
                return Tiers
            },
            clientData() {
                return this.$store.getters['client/clientData']
            },
            unreadClients() {
                return this.$store.getters['messages/getUnreadClients']
            },
            unreadMessageCountForAllClients() {
                return this.$store.getters['messages/getUnreadMessagesCountAllClients']
            },
            userData() {
                return this.$store.getters['user/userData']
            }
        },
        watch: {
            'userData'(newValue) {
                this.selected_client = newValue.client_id
            },
            selected_client(newValue) {
                const client = this.allowedClients.find(x => x.id === newValue)
                this.$store.dispatch('user/setSelectedClient', client)
            }
        },
        methods: {
            findClient: (option, label, search) => {
                const temp = search.toLowerCase()
                return option.name.toLowerCase().indexOf(temp) > -1 ||
                    option.alias.toLowerCase().indexOf(temp) > -1
            },
            async loadData() {
                try {
                    if (this.userData) {

                        const response = await this.$http.get('/api/client/v1/assigned_clients')
                        this.allowedClients = response.data ?? []

                        let client = null

                        client = this.allowedClients.find(x => x.id === this.userData.client_id)


                        if (!client && this.allowedClients.length > 0) {
                            this.selected_client = this.allowedClients[0].id
                            await this.clientChanged()
                        }

                        if (this.allowedClients.length > 0) {
                            await this.$store.dispatch('user/setSelectedClient', client)
                            await this.$store.dispatch('user/setUserNoClients', true)
                        } else {
                            await this.$store.dispatch('user/setUserNoClients', false)
                        }
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            getOptionName(nameIn, len = 15) {
                let name = nameIn.substring(0, len)
                if (name.length === len) {
                    name += '...'
                }

                return name
            },
            async clientChanged() {
                try {
                    const response = await this.$http.get(`/api/client/v1/change_client/${this.selected_client}`)
                    await this.$store.dispatch('user/setUserData', response.data)

                    const res = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
                    const permissions = res.data ?? []

                    const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
                    const clientData = res1.data ?? {}

                    await this.$store.dispatch('user/setUserPermissions', permissions)
                    await this.$store.dispatch('client/setClientData', clientData)

                    await this.$store.dispatch('setup/getSetupProgress')
                    await this.$store.dispatch('messages/set_messages')

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.installation_wizard.changing_clients'))
                } finally {
                    if (localStorage.getItem('progress') === '0') {
                        if (this.$router.currentRoute.name !== 'installation-wizard') {
                            await this.$router.push('/installation-wizard')
                        }
                    } else if (this.$router.currentRoute.name !== 'home') {
                        await this.$router.push({name: 'home'})
                    }
                }
            }
        },
        setup(props) {
            const {
                isMouseHovered,
                isVerticalMenuCollapsed,
                collapseTogglerIcon,
                toggleCollapsed,
                updateMouseHovered
            } = useVerticalNavMenu(props)

            const { skin } = useAppConfig()

            // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
            const shallShadowBottom = ref(false)

            provide('isMouseHovered', isMouseHovered)

            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false
            }

            const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

            // App Name
            const { appName, appLogoImageName, appLogoImage } = $themeConfig.app

            return {
                navMenuItems,
                perfectScrollbarSettings,
                isVerticalMenuCollapsed,
                collapseTogglerIcon,
                toggleCollapsed,
                isMouseHovered,
                updateMouseHovered,
                collapseTogglerIconFeather,

                // Shadow Bottom
                shallShadowBottom,

                // Skin
                skin,

                // App Name
                appName,
                appLogoImageName,
                appLogoImage
            }
        },
        mounted() {
            if (this.userData) {
                this.selected_client = this.userData.client_id
                this.loadData()
            }
        }
    }
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__dropdown-option--highlight {
    background: #e7eae7 !important;
}

.unreadDot{
  position: absolute;
  top: 3px;
  right: -16px;
  height: 12px;
  width: 12px;
  background-color: #e8af44;
  border-radius: 50%;
  display: inline-block;
  color: white;
  font-size: 12px;
  text-align: center;
}

.notification-dot {
  position: absolute;
  top: 10px;
  right: 5px;
  height: 16px;
  width: 16px;
  background-color: #e8af44;
  border-radius: 50%;
  display: inline-block;
  color: white;
  font-size: 12px;
  text-align: center;
}
//.clientFilter .vs__dropdown-toggle{
//  height: 57px !important;
//}

</style>

<style scoped>
.blackText{
  color: black !important;
}
</style>

