<template>
    <layout-vertical>
        <router-view />

        <template #navbar="{ toggleVerticalMenuActive }">
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </template>

        <app-customizer
            v-if="showCustomizer"
            slot="customizer">
        </app-customizer>
    </layout-vertical>

</template>

<script>
    import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
    import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
    import Navbar from '../components/Navbar.vue'

    export default {
        components: {
            AppCustomizer,
            LayoutVertical,
            Navbar
        },
        data() {
            return {
                showCustomizer: this.$store.getters['setup/setupProgress'] < 2

            }
        },
        mounted() {
            this.showCustomizer = JSON.stringify(this.$store.getters['setup/setupProgress'] < 2)
        }
    }
</script>
