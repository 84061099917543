import i18n from '@/i18n/i18n'
import useAppConfig from '@core/app-config/useAppConfig'

function getTheme()  {
    const { skin } = useAppConfig()
    return skin.value
}
export const baseChartOption = {
    xaxis: {
        tickAmount: 10,
        labels: {
            hideOverlappingLabels: true,
            rotate: -55,
            style: {
                fontSize: '12px'
            }
        }
    },
    yaxis: {
        labels: {
            style: {
                fontSize: '14px'
            }
        }
    },
    dataLabels: {
        enabled: false
    },
    grid: {
        show: false
    },
    chart: {
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        background: 'transparent'
    },
    noData: {
        text: i18n.t('general.no_data')
    },
    theme: {
        mode: getTheme()
    },
    stroke: {
        width: 0.5
    }
}

function isObject(item) {
    return item !== null && typeof item === 'object' && !Array.isArray(item)
}
export function mergeObjects(obj1, obj2) {
    const merged = { ...obj1 }

    for (const key in obj2) {
        if (isObject(obj2[key])) {
            if (isObject(merged[key])) {
                // If both values are objects, recursively merge them
                merged[key] = mergeObjects(merged[key], obj2[key])
            } else {
                // If obj1 doesn't have an object at this key, assign obj2's value
                merged[key] = mergeObjects({}, obj2[key])
            }
        } else {
            // If values are different or key doesn't exist in obj1, use obj2's value
            merged[key] = obj2[key]
        }
    }

    return merged
}