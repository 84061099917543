<template>
    <ul>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in displayedItems"
            :key="item.header || item.title"
            :item="item"
        />
    </ul>
</template>

<script>
    import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
    import { provide, ref } from '@vue/composition-api'
    import VerticalNavMenuHeader from '../vertical-nav-menu-header'
    import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
    import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

    export default {
        components: {
            VerticalNavMenuHeader,
            VerticalNavMenuLink,
            VerticalNavMenuGroup
        },
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        computed: {
            displayedItems() {
                const test = []

                parent: for (let i = 0; i < this.items.length; i++) {

                    if (this.items[i].tier && this.items[i].tier.length > 0) {
                        if (!this.$hasTierPermission(...this.items[i].tier)) {
                            continue
                        }
                    }

                    if (this.items[i].role && this.items[i].role.length > 0) {
                        let hasPermission = false
                        for (const permissionId of this.items[i].role) {
                            if (this.$hasPermission(permissionId))   {
                                hasPermission = true
                            }
                        }

                        if (!hasPermission) {
                            continue parent
                        }
                    }

                    if (this.items[i].children && this.items[i].children.length > 0) {
                        const newItem = {
                            children: [],
                            icon: this.items[i].icon,
                            title: this.items[i].title
                        }

                        for (const child of this.items[i].children) {

                            if (child.tier && child.tier.length > 0) {
                                if (!this.$hasTierPermission(...child.tier)) {
                                    continue
                                }
                            }

                            if (child.role && child.role.length > 0) {
                                for (const permissionId of child.role) {
                                    if (this.$hasPermission(permissionId))   {
                                        newItem.children.push(child)
                                        break
                                    }
                                }
                            } else {
                                newItem.children.push(child)
                            }
                        }

                        if (newItem) {
                            test.push(newItem)
                        }
                    } else {
                        test.push(this.items[i])
                    }
                }

                return test
            }
        },
        setup() {
            provide('openGroups', ref([]))

            return {
                resolveNavItemComponent
            }
        }
    }
</script>
